import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Joinpresalenow from "../Components/Joinpresalenow";
import globebg from "../Assets/img/Bg/bg-2.png";
import anglebg from "../Assets/img/Posts/bg-angle.png";
import partnershipbg from "../Assets/img/Bg/bg-dawn.png";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import angle from "../Assets/img/Posts/angle.png";
import buy from "../Assets/img/Posts/buy.png";
import dashboard from "../Assets/img/Posts/dashboard.png";
import mobile from "../Assets/img/Posts/mobile.png";
import wallet from "../Assets/img/Posts/connectwalley.png";
import cryptoindex from "../Assets/img/Posts/cryptoindex.png";
import choosyield from "../Assets/img/Posts/choos-yield.png";
import arrowrighty from "../Assets/img/Icons/arrow.png";
import iconbasket from "../Assets/img/Icons/icon-basket.png";
import iconyield from "../Assets/img/Icons/icon-yield.png";
import icongrowth from "../Assets/img/Icons/icon-growth.png";
import Header from "../Components/Header";
import { PresaleButton, PresalesButton } from "../Components/Buttons";
import UserContest from "../ContextAPI/UserContest";
import { Signup } from "../Api";
import { useAccount, useDisconnect } from "wagmi";
import Aboutlist from "../Components/Aboutlist";
import BuyToken from "../Components/BuyToken";
function Home({
  setSignupShow,
  RewardSectionRef,
  TokenSectionRef,
  RoadSectionRef,
  FAQeSectionRef,
  SignupShow,
  PlateformSectionRef,
  IndexesSectionRef,
  setShowNavBar,
  setShowFooter,
}) {
  const { CurrentStage, TotalSaleInStage } = useContext(UserContest);
  useEffect(() => {
    setShowNavBar(true);
    setShowFooter(true);
  }, []);
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected } = useAccount();
  // RewardSectionRef={RewardSectionRef}
  // TokenSectionRef={TokenSectionRef}
  // RoadSectionRef={RoadSectionRef}
  // WhiteSectionRef={WhiteSectionRef}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      {/* Header Starts Here */}
      <Header setSignupShow={setSignupShow} SignupShow={SignupShow} />
      {/* Header Starts Here */}

      {/* Buy Token */}
      <BuyToken setSignupShow={setSignupShow} SignupShow={SignupShow} />
      {/* Buy Token */}

      {/* Join Pre Sale Now Panel */}
      <Joinpresalenow setSignupShow={setSignupShow} />
      {/* Join Pre Sale Now Panel */}

      {/* Onboard Seamlessly */}
      <section className="text-container container-fluid">
        <Row onClick={() => {
                    setSignupShow(true);
                  }}>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center tablet-view">
                <h2 className="chakra">
                  <img src={arrowleft} alt="Arrow Left" /> Onboard Seamlessly{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h2>
                <div className="spacer-10" />
                <h4 className="gradient-color">
                  Quickly get setup and start allocating assets to a premium
                  selection of indexes or start creating one in minutes
                </h4>
                <div className="spacer-40" />
                {/* <img src={post1} alt="Post" /> */}
              </Col>
              <Col xl="12" lg="12" md="12">
                <div className="anime-text-pnl">
                  <div className="anime-img-panel">
                    <span />
                    <span />
                    <span />
                    <img className="angle-img" src={angle} alt="Angle" />
                    <div className="img-pnl">
                      <div className="img-pnl-inner">
                        <img src={wallet} alt="wallet" />
                        <img src={buy} alt="buy" />
                      </div>
                    </div>
                  </div>
                  <div className="txt">
                    <div className="web-view-md">
                      <h2 className="chakra">
                        <img src={arrowleft} alt="Arrow Left" /> Onboard
                        Seamlessly <img src={arrowright} alt="Arrow Right" />
                      </h2>
                      <div className="spacer-10" />
                      <h4 className="gradient-color">
                        Quickly get setup and start allocating assets to a
                        premium selection of indexes or start creating one in
                        minutes
                      </h4>
                    </div>
                    <div className="spacer-20" />
                    <p>
                      The <span className="light-yellow">New Dawn Finance</span>{" "}
                      platform makes it easy for you to get started right away
                      with a smart intuitive interface.
                    </p>
                    <p>
                      <span className="light-yellow">New Dawn Finance</span> is
                      cross chain compatible to enable one of the widest ranges
                      of members to use it.
                    </p>
                    <p>
                      Quickly connect with a variety of the most popular wallets
                      including Metamask, Coinbase and more.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Onboard Seamlessly */}
      <div
          ref={PlateformSectionRef}
        > 
      <div className="bg-container" >
     
        <div
          className="bgg-layer"
          style={{ backgroundImage: `url(${globebg})` }}
        />
        {/* PlateForm featured */}
       
        <section  className="text-container index-creator-panel container-fluid">
                         <Row onClick={() => {
                    setSignupShow(true);
                  }}>
            <Container>
              <Row  >
                <Col xl="12" lg="12" md="12" className="text-center">
                  <h2>
                    {" "}
                    <img src={arrowleft} alt="Arrow Left" /> Platform Features{" "}
                    <img src={arrowright} alt="Arrow Right" />
                  </h2>
                  <h4 className="gradient-color max-width-600">
                    Whether you are ready to select from a menu of indexes or if
                    you’re looking to create new enticing ones, New Dawn Finance
                    offers the tools for managing a wide range of strategies in
                    the DeFi industry.
                  </h4>
                  <div className="spacer-30" />
                </Col>
                <Col xl="5" lg="5" md="5" className="text-center">
                  <div className="txt align-items-center">
                    <h3 className="gradient-color chakra">
                      For Index Selectors
                    </h3>
                    <p>
                      The New Dawn Finance platform functions like a First Class
                      meal where you get to pick from a selection of only the
                      top DeFi strategies.
                    </p>
                    <div className="spacer-20" />
                  </div>
                </Col>
                <Col xl="7" lg="7" md="7" className="text-center">
                  <div className="white-shadow img-pnl">
                    <img src={cryptoindex} alt="post" />
                    <img src={cryptoindex} alt="post" />
                  </div>
                </Col>
                <Col xl="12" lg="12" md="12">
                  <div className="spacer-40" />
                </Col>
              </Row>
              <Row>
                <Col
                  xl={{ span: 5, order: 2 }}
                  lg={{ span: 5, order: 2 }}
                  md={{ span: 5, order: 2 }}
                  className="text-center"
                >
                  <div className="txt align-items-center">
                    <h3 className="gradient-color chakra">
                      For Index Creators
                    </h3>
                    <p>
                      Create your own carefully curated menu of DeFi strategies
                      and serve them up to the New Dawn Finance community with
                      precise fine tuning.
                    </p>
                    <div className="spacer-20" />
                  </div>
                </Col>
                <Col xl="7" lg="7" md="7" className="text-center">
                  <div className="white-shadow img-pnl">
                    <img src={choosyield} alt="post" />
                    <img src={cryptoindex} alt="post" />
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        </div>

        {/* PlateForm featured */}

        {/* About List */}
        <section className="container-fluid pt-0 pb-0">
          <Row>
            <Container>
              <Row onClick={() => {
                    setSignupShow(true);
                  }}>
                <Col xl="12" lg="12" md="12" sm="12">
                  <Aboutlist />
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* About List */}

        {/* Unlimited Index */}
        <section ref={IndexesSectionRef} className="container-fluid index-pnl">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${partnershipbg})` }}
          />
          <Row onClick={() => {
                    setSignupShow(true);
                  }}>
            <Container>
              <Row  >
                <Col xl="5" lg="5" md="4" sm="12">
                  <div className="mobile-text-center">
                    <h2 className="mobile-break z-ind">
                      <img src={arrowleft} alt="Arrow Left" /> <br /> Unlimited
                      <br /> Index
                      <br /> Types
                      <br /> <img src={arrowright} alt="Arrow Right" />
                    </h2>
                    <div className="spacer-20" />
                    <h4 className="">
                      Imagine the possibilities of what you can
                      <br /> select or create with New Dawn Finance!
                    </h4>
                  </div>
                  <div className="spacer-20" />
                </Col>
                <Col xl="7" lg="7" md="8" sm="12">
                  <ul className="index-list">
                    <li>
                      <img src={iconbasket} alt="Icon Basket" />
                      <div className="txt-pnl">
                        <h4 className="chakra">
                          Basket <img src={arrowrighty} alt="Arrow Right" />
                        </h4>
                        <p>
                          An enticing basket of the top 5 cryptos including the
                          likes of Bitcoin, Ethereum, Uniswap, etc.
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src={iconyield} alt="Icon Basket" />
                      <div className="txt-pnl">
                        <h4 className="chakra">
                          yield <img src={arrowrighty} alt="Arrow Right" />
                        </h4>
                        <p>
                          A dynamic index that allocates underlying assets to
                          the highest APY yield farming pools.
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src={icongrowth} alt="Icon Basket" />
                      <div className="txt-pnl">
                        <h4 className="chakra">
                          Growth <img src={arrowrighty} alt="Arrow Right" />
                        </h4>
                        <p>
                          An astounding index that automatically adjusts to the
                          3 fastest growing cryptos.
                        </p>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* Unlimited Index */}

        {/* Get Started Now */}
        <section className="text-container container-fluid bg-shadow">
          <Row onClick={() => {
                    setSignupShow(true);
                  }}>
            <Container>
              <Row>
                <Col xl="12" lg="12" md="12">
                  <div className="anime-text-pnl get-panel">
                    <div className="anime-img-panel">
                      <span />
                      <span />
                      <span />
                      <img className="angle-img" src={angle} alt="Angle" />
                      <div className="img-pnl">
                        <div className="img-pnl-inner">
                          <img src={dashboard} alt="Dashboard" />
                          <img src={mobile} alt="mobile" />
                        </div>
                      </div>
                    </div>
                    <div className="txt mobile-text-center">
                      <h2>
                        <img src={arrowleft} alt="Arrow Left" /> Get started
                        now! <img src={arrowright} alt="Arrow Right" />
                      </h2>
                      <div className="spacer-20" />
                      <h4 className="whitecolor">
                        You’ll find that the{" "}
                        <span className="yellow-color">New Dawn Finance</span>{" "}
                        platform is easy to use and intuitive so you can get{" "}
                        <span className="yellow-color">New Dawn Financing</span>{" "}
                        right away. Welcome to a new world of DeFi.
                      </h4>
                      <div className="spacer-30" />
                      <Link className="reg-btn br-radius" href="#">
                        GET STARTED
                      </Link>
                      <div className="spacer-20" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* Get Started Now */}
      </div>
    </>
  );
}
export default Home;
