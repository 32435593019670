import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Row,
  Container,
  Col,
  Button,
  Modal,
  Form,
  ProgressBar,
} from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import sold from "../../Assets/img/sold.png";
import endpoint from "../../Assets/img/admin-icons/endpoint.png";
import icontether from "../../Assets/img/Icons/icon-teather.png";
import check from "../../Assets/img/admin-icons/icon-check.png";
import iconi from "../../Assets/img/admin-icons/icon-i-b.png";
import iconip from "../../Assets/img/admin-icons/icon-i.png";
import iconerror from "../../Assets/img/admin-icons/icon-error.png";
import iconlogo from "../../Assets/img/admin-icons/icon-logo.png";
import iconlogosm from "../../Assets/img/admin-icons/icon-logo-sm.png";
import iconusd from "../../Assets/img/admin-icons/icon-tether.png";
import iconeth from "../../Assets/img/admin-icons/icon-etherium.png";
import iconbnb from "../../Assets/img/admin-icons/icon-bnb.png";
import arrowleft from "../../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../../Assets/img/Icons/icon-arrow-2.png";
import iconaddress from "../../Assets/img/admin-icons/icon-address.png";
import ChatButton from "../../Components/ChatButton";
import UserContest from "../../ContextAPI/UserContest";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  GetSelectedCurrencyPrice,
  GraphTotal,
  UpdateProfileApi,
  WalletaddressChanage,
  WalletaddressRequest,
  getBonusTokenCountWithOutStage,
  getTokenBonusCount,
  getTotalTokenOfUser,
  getUserDetail,
} from "../../Api";
import { Notifications } from "../../Components/Toast";
import {
  CopyToClipboardButton,
  validateEthereumAddress,
} from "../../Constants/utils";
import OtpInput from "react-otp-input";
import { CommasRegex } from "../../Constants";

function Dashboard({ setShowFooter, setShowNavBar }) {
  const [walletaddressShow, setwalletaddressShow] = useState(false);
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTP, setOTP] = useState();
  const [editwalletaddressShow, seteditwalletaddressShow] = useState(false);
  const [percentage, setPercentage] = useState();
  const [BonusToken, setBonusToken] = useState();
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  const [userBalance, setUserBalance] = useState();
  const navigate = useNavigate();

  const [saleGraph, setsaleGraph] = useState();
  const [wallerAddress, setWalletAddsress] = useState();
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  const [BNBvalue, setBNBValue] = useState();

  const {
    CurrentStage,
    TotalSaleInStage,
    UserDetail,
    SetUserDetail,
    Promotioncode,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
  } = useContext(UserContest);
  useEffect(() => {
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // console.log('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased));
        })
        .catch(() => {});
    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased));
      })
      .catch(() => {});

    GraphTotal()
      .then((data) => {
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => {});
  }, [CurrentStage]);
  useEffect(() => {
    let percentage =
      (parseInt(saleGraph) / parseInt(CurrentStage?.total_limit)) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)

    let remaining = parseInt(
      parseInt(CurrentStage?.total_limit) -
        (parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken) >
        parseInt(CurrentStage?.total_limit)
          ? parseInt(CurrentStage?.total_limit)
          : parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken))
    );
    let sold = parseInt(parseInt(TotalSaleInStage) + parseInt(BonusToken));

    setPercentage(parseFloat((sold / (remaining + sold)) * 100).toFixed(2));

    //  setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);
  useEffect(() => {
    getTotalTokenOfUser(UserDetail?.id)
      .then((data) => {
        setUserBalance(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    GetSelectedCurrencyPrice("bnbbsc")
      .then((price) => {
        // console.log(price,'price')
        setBNBValue(price);
      })
      .catch(() => {});
  }, [UserDetail]);

  const UpdateWalletAddress = () => {
    UserDetail.wallet_address = wallerAddress;
    UpdateProfileApi(UserDetail)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }
            navigate("/");
          });
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => {});
  };

  const Walletaddresschanage = () => {
    WalletaddressChanage(UserDetail?.id)
      .then(() => {
        Notifications("success", "OTP has sent to your Mail");
      })
      .catch(() => {});
  };
  const Walletaddressrequest = () => {
    const data = {
      user_id: UserDetail?.id,
      otp: OTP,
      wallet_address: wallerAddress,
    };
    WalletaddressRequest(data)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }
            navigate("/");
          });
        setShowOTP(false);
        setOTP("");
        setwalletaddressShow(false);
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => {
        // Notifications("success", "Entered OTP is incorrect");
      });
  };
  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <div className="flex-contr lg">
                <div className="w-7 lg-f token-blnce-pnl flex-div-sm align-items-center">
                  <ul className="my-token-list w-100 trans">
                    <li>
                      <p>
                        <b>Account Status</b>
                      </p>
                      <span className="border-span"/>
                      <p>
                        <b>
                          <img src={check} alt="Verified" /> Verified
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Receiving Wallet</b>
                      </p>
                      <span className="border-span"/>

                      <p>
                        {UserDetail?.wallet_address === "null" ||
                          (UserDetail?.wallet_address === null && (
                            <Button
                              className="simple-link purple  tooltip-span"
                              onClick={() => {
                                setwalletaddressShow(true);
                              }}
                            >
                              Add your wallet address
                            </Button>
                          ))}
                        {UserDetail?.wallet_address !== "null" &&
                          UserDetail?.wallet_address && (
                            <>
                              <p
                                onClick={() =>
                                  CopyToClipboardButton(
                                    UserDetail?.wallet_address,
                                    "Address Copied Successfully"
                                  )
                                }
                                className="color m-0 cursor d-flex align-items-center"
                              >
                                {`${UserDetail?.wallet_address?.slice(
                                  0,
                                  5
                                )}...${UserDetail?.wallet_address?.slice(-5)}`}
                                <Button
                                  className="simple-link purple ml-2"
                                  onClick={() => setwalletaddressShow(true)}
                                >
                                  Edit
                                </Button>
                              </p>
                            </>
                          )}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </Row>

            <Row>
              <div className="flex-contr lg">
                <div className="blue-post w-7 lg-f token-blnce-pnl">
                  <div className="text-center">
                    <h3 className="chakra">
                      <img src={arrowleft} alt="Arrow Left" />{" "}
                      {CurrentStage?.level}{" "}
                      <img src={arrowright} alt="Arrow Right" />
                    </h3>
                    <div className="spacer-20" />
                  </div>
                  <div className=" flex-div-sm align-items-center">
                    <div className="stage-panel text-center">
                      {/* Talha */}
                      <ul className="bonus-percentage-list full">
                        <li className="circle-heading">
                          <h1>
                            <span className="f-18 w-100 d-inline-block">
                              Sold
                            </span>
                            <div className="d-flex">{percentage} %</div>
                            {/* 35.21% */}
                          </h1>
                        </li>
                      </ul>
                    </div>
                    <div className="text-pnl">
                      {/* <CircularProgressbar
                        value={percentage / 100}
                        maxValue={1}
                        text={`${percentage}%`}
                      /> */}

                      {/* Talha */}
                      <div className="flex-div">
                        <h5 className="m-0 whitecolor ">
                          Tokens remaining in stage:
                        </h5>
                        <h5 className="m-0 font-weight-bold">
                          {/* 25,785,123 */}
                          {parseFloat(
                            parseInt(
                              parseInt(CurrentStage?.total_limit) -
                                (parseInt(TotalSaleInStage ?? 0) +
                                  parseInt(BonusToken) >
                                parseInt(CurrentStage?.total_limit)
                                  ? parseInt(CurrentStage?.total_limit)
                                  : parseInt(TotalSaleInStage ?? 0) +
                                    parseInt(BonusToken))
                            )
                          )
                            ?.toString()
                            .replace(CommasRegex, ",")}
                        </h5>
                      </div>
                      <div className="spacer-10" />
                      <div className="progress-bar">
                        {/* <ProgressBar  now={70} label={`${70}%`} />
                      <div className="measure-bar">
                            <img src={endpoint} alt="Arrow" />
                          </div> */}
                        <div
                          className="bar"
                          style={{ width: `${percentage}%` }}
                        >
                          <ProgressBar
                            className="custom-progress-bar"
                            now={percentage / 100}
                          />
                          <div className="measure-bar">
                            <img src={endpoint} alt="Arrow" />
                          </div>
                        </div>
                        {/* <div className="bar" style={{ width: "40%" }}>
                        <div className="measure-bar">
                            <img src={endpoint} alt="Arrow" />
                          </div>
                        </div> */}
                      </div>
                      <div className="spacer-30" />
                      {/* Talha */}
                      <ul className="my-token-list mb-none w-100 mb-3">
                        <li className="mb-2">
                          <p>
                            <img src={iconlogosm} alt="Logo" /> $NDFT price in
                            current stage:
                          </p>
                          <p>
                            <b>
                              {" "}
                              {CurrentStage?.price_of_token || ""}{" "}
                              <img
                                className="ml-1"
                                src={icontether}
                                alt="Usdt"
                              />
                            </b>
                          </p>
                        </li>
                        <li>
                          <p>The next stage price:</p>
                          <p>
                            <b>
                              {/* 0.0758  */}
                              {CurrentStage?.futuretokenprice}
                              <img
                                className="ml-1"
                                src={icontether}
                                alt="Usdt"
                              />
                            </b>
                          </p>
                        </li>
                      </ul>
                      {/* {parseFloat(BNBvalue ?? 0).toFixed(4)} BNB */}

                      {/* Talha */}
                      <ul className="my-token-list w-100">
                        <li>
                          <p>Tokens Sold In Total:</p>
                          <p>
                            <b>
                              {/* 11,284,711 */}
                              {(
                                parseInt(OverAllSaleToken) +
                                parseInt(bonusWithoutStage)
                              )
                                ?.toString()
                                .replace(CommasRegex, ",") ?? 0}
                            </b>
                          </p>
                        </li>
                        <li>
                          <p>Total Raised:</p>
                          <p>
                            <b>
                              ${/* 2,120,185 */}
                              {(
                                (parseInt(OverAllSaleToken) +
                                  parseInt(bonusWithoutStage)) *
                                0.04
                              )
                                .toFixed(3)
                                ?.toString()
                                .replace(CommasRegex, ",") ?? 0}
                            </b>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="blue-post w-5 lg-f token-blnce-pnl">
                  <div className="text-center">
                    <h3 className="chakra">
                      <img src={arrowleft} alt="Arrow Left" /> My Token Balance{" "}
                      <img src={arrowright} alt="Arrow Right" />
                      <span className="tooltip-span">
                        <img src={iconi} alt="Icon i" />
                        <span>
                          Equivalent to ~{" "}
                          {CurrentStage?.price_of_token * userBalance ?? 0} USD
                        </span>
                      </span>
                    </h3>

                    <div className="d-flex justify-content-center align-items-center">
                      <h1 className=" font-weight-bold chakra">
                        {userBalance ?? 0}
                      </h1>
                      <h4 className="font-weight-normal mb-0 ml-3">
                        <img className="mr-1" src={iconlogo} alt="Icon Logo" />{" "}
                        NDFT
                      </h4>
                    </div>
                    {/* <div className="top-line">
                      {Promotioncode?.name && (
                        <p>
                          Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
                          {Promotioncode?.bonus}% <span>bonus!</span>
                        </p>
                      )}
                    </div> */}
                  </div>
                  <div className="spacer-20" />
                  <div className="purple-post text-center m-0">
                    <div className="spacer-10" />
                    <h5 className="whitecolor chakra">
                      <b>
                        <img src={arrowleft} alt="Arrow Left" /> Balance in
                        other currencies:{" "}
                        <img src={arrowright} alt="Arrow Right" />
                      </b>
                    </h5>
                    <ul className="my-token-list w-100 p-0">
                      <li>
                        <p>
                          <img
                            className="ml-1 img-sp"
                            src={iconusd}
                            alt="Icon Usd"
                          />{" "}
                          USD{" "}
                        </p>
                        <p>
                          <b> 0</b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            className="ml-1 img-sp"
                            src={iconeth}
                            alt="Icon Eth"
                          />{" "}
                          ETH{" "}
                        </p>
                        <p>
                          <b>0</b>
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            className="ml-1 img-sp"
                            src={iconbnb}
                            alt="Icon BNB"
                          />{" "}
                          BNB{" "}
                        </p>
                        <p>
                          <b>0</b>
                        </p>
                      </li>
                    </ul>
                    <div className="spacer-10"/>
                  </div>
                </div>
              </div>
              <Col xl="12" lg="12" md="12" className="text-center">
                <div className="spacer-30" />
                <Link
                  className="reg-btn br-radius big letter-spacing-1"
                  to="/PurchaseToken"
                >
                  <b>BUY TOKEN NOW</b>
                </Link>
              </Col>
            </Row>

            {/* <Row>
              <Col xl={{ span: 8, offset: 2 }} className="text-center">
                <div className="spacer-20"/>
                <h3>
                  A Decentralized algorithmic crypto trading platform to Revolutionize trading and investment with innovative technologies and automation
                </h3>
                <div className="spacer-50"/>
              </Col>
            </Row> */}
          </Container>
        </Row>
      </section>

      {/* Wallet Address Modal Starts Here */}

      <Modal
        size="lg"
        show={walletaddressShow}
        centered
        onHide={() => setwalletaddressShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setwalletaddressShow(false)}
          >
            <i className="fa fa-close"/>
          </Button>
          <h3 className="text-center chakra">
            <img src={arrowleft} alt="Arrow Left" /> Wallet Address{" "}
            <img src={arrowright} alt="Arrow Right" />
          </h3>
          <div className="spacer-20" />
          <p className="lightgrey-color">
            To receive your <b>$IMToken</b> tokens, you will need to enter your
            Ethereum wallet address in the box below. You can claim your{" "}
            <b>$IMToken</b> tokens when the presale ends.
          </p>
          <div className="spacer-10" />

          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Enter Wallet Address</Form.Label>
              <Form.Control
                type="text"
                value={wallerAddress}
                onChange={(e) => {
                  setWalletAddsress(e.target.value);
                }}
                autoComplete="new-email"
                className="input-email"
                placeholder="Enter your Ethereum wallet address"
              />
              {wallerAddress && !validateEthereumAddress(wallerAddress) && (
                <p style={{ color: "red" }}>
                  Please enter a valid wallet Address
                </p>
              )}
            </Form.Group>
          </Form>
          <div className="d-flex align-items-start mb-2">
            <img className="mr-2" src={iconerror} alt="Icon error" />
            <p className="m-0 ">
              Address should be <b>ERC-20</b> compliant.
            </p>
          </div>
          <div className="d-flex align-items-start">
            <img className="mr-2" src={iconerror} alt="Icon error" />
            <p className="m-0">
              <b>Do NOT</b> use your exchange wallet address or a wallet for
              which you do not have a private key. If you do, you will not
              receive your Tokens and could lose your funds.
            </p>
          </div>
          <div className="spacer-30" />
          <div className="text-center">
            <Button
              disabled={!validateEthereumAddress(wallerAddress)}
              onClick={() => {
                Walletaddressrequest();
                //  setShowOTP(true)
                //  setOTP(false)
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={ShowOTP}
        centered
        onHide={() => setShowOTP(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button className="close-btn" onClick={() => setShowOTP(false)}>
            <i className="fa fa-close"/>
          </Button>
          <h3 className="text-center chakra">
            <img src={arrowleft} alt="Arrow Left" /> OTP has sent to your
            Registered Email <img src={arrowright} alt="Arrow Right" />
          </h3>
          <div className="spacer-20" />
          <div className="otpinput">
            <OtpInput
              value={OTP}
              onChange={setOTP}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>

          <div className="text-center">
            <a
              className="text-center simple-link"
              onClick={() => Walletaddresschanage()}
              style={{ cursor: "pointer" }}
            >
              Resend OTP
            </a>

            <div className="spacer-20" />
            <Button
              onClick={() => {
                Walletaddressrequest();
              }}
              className="reg-btn br-radius letter-spacing-1 m-0"
            >
              Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Dashboard;
