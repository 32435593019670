import React from 'react';

function DisplayUserName({ userDetail }) {
  let displayName;

  if (userDetail && userDetail.name) {
    const name = userDetail.name;

    if (name.length <= 10) {
      // If name length is 4 characters or less, show the first 4 characters followed by '...'
      displayName = (
        <>
          <span>{name.slice(0,1)} </span>
          {name }
        </>
      );
    } else {
      // If name length is more than 4 characters, swap 'WW' with initials 'ZS'
      const initials = name.split(' ').map(word => word[0]).join('');
      displayName = (
        <>
          <span>{initials}</span>
          {name.slice(0, 10) + '...'}
        </>
      );
    }
  } else {
    // If userDetail or userDetail.name is undefined, set a default display
    displayName = <span>No Name</span>;
  }

  return <div>{displayName}</div>;
}

export default DisplayUserName;
