import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import bg from "../Assets/img/Bg/bg-1.png";
import { Row, Container, Col } from "react-bootstrap";
import  videoSource  from "../Assets/img/video/dawn.mp4";

function Header({ setSignupShow, SignupShow }) {

  return (
    <>
      <Container fluid className="landing-header header text-center">
        <div className="bg-layer">
          <div className="video-wrapper">
            <video
              className="bg-layer__video"
              autoPlay
              loop
              playsInline
              muted
            >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {/* Your other React components/content goes here */}
          </div>
        </div>
        <Row>
          <Container>
            <Row
              onClick={() => {
                setSignupShow(true);
              }}
            >
              <Col xl="12" lg="12">
                <div className="text-pnl">
                  <h5>Accessibility, Transparency, Security</h5>
                  <h1>
                    A Revolution in DeFi<br></br> Asset Management
                  </h1>
                  <div className="spacer-20" />
                  <h4 className="font-weight-normal">
                    <span className="text-gradient">
                      <b>New Dawn Finance</b>
                    </span>{" "}
                    is a platform that brings together the world of
                    decentralized finance and offers it up as a carefully
                    curated menu of indexes.{" "}
                  </h4>
                  <div className="spacer-20" />
                  <ul className="btn-list">
                    <li>
                      <Link className="reg-btn br-radius" href="#">
                        Get Started
                      </Link>
                    </li>
                    <li>
                      <Link className="reg-btn trans br-radius" href="#">
                        How to join
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default Header;
