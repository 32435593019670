import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import featured1 from "../Assets/img/Featured/Featured-1.png";
import featured2 from "../Assets/img/Featured/Featured-2.png";
import featured3 from "../Assets/img/Featured/Featured-3.png";
import CryptoNews from "../Assets/img/Featured/cryptonews.png";
import cryptodaily from "../Assets/img/Featured/cryptodaily.png";
import bscnews from "../Assets/img/Featured/bscnews.png";
function LatestNewsSlider() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div className="Featuredslide">
          {" "}
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured1} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Norway regulators clamp down on crypto mining via new law
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={CryptoNews} alt="Crypto News" />
                  CryptoNews
                </h6>
                <h6 className="lighter-yellow">17/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured2} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Dormant Ethereum (ETH) Whale Becomes Active After Years, Invests
                $50K In 25X Exchange Token DTX
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={cryptodaily} alt="Crypto Daily" />
                  Cryptodaily
                </h6>
                <h6 className="lighter-yellow">16/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured3} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Algotech (ALGT) Grabs Attention Away From Polygon: Could It
                Surpass MATIC Price in 2024?
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={bscnews} alt="BSC News" />
                  bsc.news
                </h6>
                <h6 className="lighter-yellow">7/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          {" "}
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured1} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Norway regulators clamp down on crypto mining via new law
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={CryptoNews} alt="Crypto News" />
                  CryptoNews
                </h6>
                <h6 className="lighter-yellow">17/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured2} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Dormant Ethereum (ETH) Whale Becomes Active After Years, Invests
                $50K In 25X Exchange Token DTX
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={cryptodaily} alt="Crypto Daily" />
                  Cryptodaily
                </h6>
                <h6 className="lighter-yellow">16/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured3} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Algotech (ALGT) Grabs Attention Away From Polygon: Could It
                Surpass MATIC Price in 2024?
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={bscnews} alt="BSC News" />
                  bsc.news
                </h6>
                <h6 className="lighter-yellow">7/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          {" "}
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured1} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Norway regulators clamp down on crypto mining via new law
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={CryptoNews} alt="Crypto News" />
                  CryptoNews
                </h6>
                <h6 className="lighter-yellow">17/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured2} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Dormant Ethereum (ETH) Whale Becomes Active After Years, Invests
                $50K In 25X Exchange Token DTX
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={cryptodaily} alt="Crypto Daily" />
                  Cryptodaily
                </h6>
                <h6 className="lighter-yellow">16/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured3} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Algotech (ALGT) Grabs Attention Away From Polygon: Could It
                Surpass MATIC Price in 2024?
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={bscnews} alt="BSC News" />
                  bsc.news
                </h6>
                <h6 className="lighter-yellow">7/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          {" "}
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured1} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Norway regulators clamp down on crypto mining via new law
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={CryptoNews} alt="Crypto News" />
                  CryptoNews
                </h6>
                <h6 className="lighter-yellow">17/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured2} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Dormant Ethereum (ETH) Whale Becomes Active After Years, Invests
                $50K In 25X Exchange Token DTX
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={cryptodaily} alt="Crypto Daily" />
                  Cryptodaily
                </h6>
                <h6 className="lighter-yellow">16/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured3} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Algotech (ALGT) Grabs Attention Away From Polygon: Could It
                Surpass MATIC Price in 2024?
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={bscnews} alt="BSC News" />
                  bsc.news
                </h6>
                <h6 className="lighter-yellow">7/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          {" "}
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured1} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Norway regulators clamp down on crypto mining via new law
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={CryptoNews} alt="Crypto News" />
                  CryptoNews
                </h6>
                <h6 className="lighter-yellow">17/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured2} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Dormant Ethereum (ETH) Whale Becomes Active After Years, Invests
                $50K In 25X Exchange Token DTX
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={cryptodaily} alt="Crypto Daily" />
                  Cryptodaily
                </h6>
                <h6 className="lighter-yellow">16/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured3} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Algotech (ALGT) Grabs Attention Away From Polygon: Could It
                Surpass MATIC Price in 2024?
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={bscnews} alt="BSC News" />
                  bsc.news
                </h6>
                <h6 className="lighter-yellow">7/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          {" "}
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured1} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Norway regulators clamp down on crypto mining via new law
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={CryptoNews} alt="Crypto News" />
                  CryptoNews
                </h6>
                <h6 className="lighter-yellow">17/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured2} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Dormant Ethereum (ETH) Whale Becomes Active After Years, Invests
                $50K In 25X Exchange Token DTX
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={cryptodaily} alt="Crypto Daily" />
                  Cryptodaily
                </h6>
                <h6 className="lighter-yellow">16/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="Featuredslide">
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured3} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h5 className="chakra">
                Algotech (ALGT) Grabs Attention Away From Polygon: Could It
                Surpass MATIC Price in 2024?
              </h5>
              <div className="flex-div">
                <h6 className="lighter-yellow f-18">
                  <img className="mr-2" src={bscnews} alt="BSC News" />
                  bsc.news
                </h6>
                <h6 className="lighter-yellow">7/04/2024</h6>
              </div>
            </div>
          </Link>
        </div>
      </Slider>
    </>
  );
}
export default LatestNewsSlider;
