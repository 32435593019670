import React, { useReducer,useRef } from 'react'
import playerContext from './UserContest'
import playerReducer from './UserReducer'
import {
   
  CurrentStage,
  LatestBlock,
  TotalSaleInStage,
  OverAllSaleToken,
  Promocode,
  UserDetail,
  SelectedCurrency,
  Selectedvalue,
  Promotioncode,
  showTransaction,
  NavBaRSignUp
  } from './type'
const UserState = (props) => {
  const initialState = {
    LatestBlocks: 'Data',
    CurrentStage: {},
    TotalSaleInStage :0,
    OverAllSaleToken:0,
    Promocode:"",
    UserDetail :{ },
    SelectedCurrency:"",
    Selectedvalue:"",
    Promotioncode:{},
    showTransaction:false,
    NavBaRSignUp:false
  }
  const [state, dispatch] = useReducer(playerReducer, initialState)
  const SetLatestBlock = (data) =>
    dispatch({ type: LatestBlock, data:  data })
   const SetCurrentStage= (data) =>
    dispatch({ type: CurrentStage, data:  data })
    
    const SetTotalSaleInStage= (data) =>
    dispatch({ type: TotalSaleInStage, data:  data })
        
    const SetOverAllSaleToken= (data) =>
    dispatch({ type: OverAllSaleToken, data:  data })

    const SetPromocode = (data) =>
    dispatch({ type: Promocode, data:  data })

    const SetUserDetail = (data) =>
    dispatch({ type: UserDetail, data:  data })

    const SetSelectedCurrency = (data) =>
    dispatch({ type: SelectedCurrency, data:  data })
    const SetSelectedvalue = (data) =>
    dispatch({ type: Selectedvalue, data:  data })
    const SetPromotioncode = (data) =>
    dispatch({ type: Promotioncode, data:  data })
    const SetshowTransaction = (data) =>
    dispatch({ type: showTransaction, data:  data })
    const SetNavBaRSignUp = (data) =>
    dispatch({ type: NavBaRSignUp, data:  data })
    
  return (
    <playerContext.Provider
      value={{
        LatestBlocks: state.LatestBlock,
        CurrentStage:state.CurrentStage,
        TotalSaleInStage:state.TotalSaleInStage,
        OverAllSaleToken:state.OverAllSaleToken,
        Promocode:state.Promocode,
        UserDetail:state.UserDetail,
        SelectedCurrency:state.SelectedCurrency,
        Selectedvalue:state.Selectedvalue,
        Promotioncode:state.Promotioncode,
        showTransaction:state.showTransaction,
        NavBaRSignUp :state.NavBaRSignUp,
        SetLatestBlock,
        SetTotalSaleInStage,
        SetCurrentStage,
        SetOverAllSaleToken,
        SetPromocode,
        SetUserDetail,
        SetSelectedCurrency,
        SetSelectedvalue,
        SetPromotioncode,
        SetshowTransaction,
        SetNavBaRSignUp
      }}
    >
      {props.children}
    </playerContext.Provider>
  )
}

export default UserState


















