import React, { useEffect } from 'react';

const GlobalErrorBoundary = ({ children }) => {
  useEffect(() => {
    const globalErrorHandler = (event) => {
      // Log the global error with more details
    //   console.error('Global Silent Error Handling:', {
    //     message: event.message,
    //     source: event.source,
    //     lineno: event.lineno,
    //     colno: event.colno,
    //     error: event.error,
    //   });

      // Prevent the default browser error handling (script interruption)
      event.preventDefault();

      // Handle the error gracefully (e.g., log it, send it to a server)
      handleSilentError();
    };

    const componentErrorHandler = (componentError, componentErrorInfo) => {
      // Log the React component error and information
    //   console.error('React Component Silent Error Handling:', componentError);
    //   console.error('React Component Error Info:', componentErrorInfo);

      // Handle the error gracefully (e.g., log it, send it to a server)
      handleSilentError();
    };

    const unhandledRejectionHandler = (event) => {
    //   console.error('Unhandled Promise Rejection:', event.reason);

      // Handle the error gracefully (e.g., log it, send it to a server)
      handleSilentError();
    };

    const handleSilentError = (error) => {
      // Add your custom logic to handle the error without breaking the application
      // For example, you can log the error, send it to a server, or perform other actions
    //   console.log('Error handled gracefully:', error);
    };

    // Add try-catch block for specific code sections prone to undefined or null errors
    try {
      // Your code here that may cause undefined or null errors
      // For example: const someValue = someObject.property;
    } catch (error) {
      handleSilentError(error);
    }

    window.addEventListener('error', globalErrorHandler);
    window.addEventListener('unhandledrejection', unhandledRejectionHandler);

    return () => {
      window.removeEventListener('error', globalErrorHandler);
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
    };
  }, []); // Empty dependency array ensures that this effect runs once during component mount

  // Render the children without displaying an error UI
  return <>{children}</>;
};

export default GlobalErrorBoundary;
