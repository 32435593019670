import React from "react";
import Accordion from "react-bootstrap/Accordion";
function Frequentlyquestion() {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span></span>What is New Dawn Finance Protocol?
          </Accordion.Header>
          <Accordion.Body>
            Index managers can set up smart contracts and configure parameters
            like investment strategies and management fee. Investors can deposit
            cryptocurrencies into a smart contract to receive a corresponding
            amount of ckTokens to represent partial ownership of the index.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <span></span>How does the New Dawn Finance Protocol work?
          </Accordion.Header>
          <Accordion.Body>
            Index managers can set up smart contracts and configure parameters
            like investment strategies and management fee. Investors can deposit
            cryptocurrencies into a smart contract to receive a corresponding
            amount of ckTokens to represent partial ownership of the index.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <span></span>
            Why do I need to use New Dawn Finance Protocol?
          </Accordion.Header>
          <Accordion.Body>
            Index managers can set up smart contracts and configure parameters
            like investment strategies and management fee. Investors can deposit
            cryptocurrencies into a smart contract to receive a corresponding
            amount of ckTokens to represent partial ownership of the index.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <span></span>Is New Dawn Finance Protocol safe?
          </Accordion.Header>
          <Accordion.Body>
            Index managers can set up smart contracts and configure parameters
            like investment strategies and management fee. Investors can deposit
            cryptocurrencies into a smart contract to receive a corresponding
            amount of ckTokens to represent partial ownership of the index.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Frequentlyquestion;
