import React from "react";
import bg1 from "../Assets/img/Roadmap/bg-1.png";
import bg2 from "../Assets/img/Roadmap/bg-7.png";
import bg3 from "../Assets/img/Roadmap/bg-8.png";
import bg4 from "../Assets/img/Roadmap/bg-9.png";
import bg5 from "../Assets/img/Roadmap/bg-10.png";
import bg6 from "../Assets/img/Roadmap/bg-11.png";
import post1 from "../Assets/img/Icons/icon-ui.png";
import post2 from "../Assets/img/Icons/icon-fees.png";
import post3 from "../Assets/img/Icons/icon-gas.png";
import post4 from "../Assets/img/Icons/icon-protection.png";
import post5 from "../Assets/img/Icons/icon-variety.png";
import post6 from "../Assets/img/Icons/icon-trading.png";
function Aboutlist() {
  return (
    <>
      <ul className="about-list">
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg1} alt="post" />
            </span>
            <img src={post1} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>User-Friendly UI</h4>
            <p>
              A simple and sleek interface to help anyone navigate through the
              complex DeFi landscape.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg2} alt="post" />
            </span>
            <img src={post2} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Lower Initialization Fees</h4>
            <p>
              Begin index creation right away without any hefty legal or
              initiation fees.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg3} alt="post" />
            </span>
            <img src={post3} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Lower Gas Fees</h4>
            <p>
              Share gas fees with other guests to lower high transaction costs.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg4} alt="post" />
            </span>
            <img src={post4} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Strategy Protection</h4>
            <p>
              No need to disclose index creation methodology as only the index
              compositions are visible.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg5} alt="post" />
            </span>
            <img src={post5} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Index Variety</h4>
            <p>
              Get access to a variety of indexes from experienced professionals.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg6} alt="post" />
            </span>
            <img src={post6} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>DeFi Trading Tools</h4>
            <p>Access to the latest DeFi markets and trading tools.</p>
          </div>
        </li>
      </ul>
    </>
  );
}
export default Aboutlist;
