import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Form,
  Modal,
  Button,
  ProgressBar,
} from "react-bootstrap";
import icontether from "../Assets/img/Icons/icon-teather.png";
import icontether1 from "../Assets/img/Icons/icon-teather-1.png";
import sold from "../Assets/img/sold.png";
import iconcheck from "../Assets/img/Icons/icon-check.png";
import iconcross from "../Assets/img/Icons/icon-cross.png";
import iconlogo from "../Assets/img/Logo/icon-logo.png";
import coincard from "../Assets/img/Icons/icon-card.png";
import coinusdc from "../Assets/img/Icons/icon-usdc.png";
import coinbusd from "../Assets/img/Icons/icon-busd.png";
import coinbnb from "../Assets/img/Icons/icon-bnb.png";
import coincrypto from "../Assets/img/Icons/icon-crypto.png";
import coineth from "../Assets/img/Icons/icon-eth.png";
import coinusdt from "../Assets/img/Icons/icon-usdt.png";
import Speed from "../Assets/img/Icons/icon-efficiancy.png";
import Consistency from "../Assets/img/Icons/icon-consistancy.png";
import data from "../Assets/img/Icons/icon-data-driven.png";
import Accessibility from "../Assets/img/Icons/icon-accesability.png";
import sunbg from "../Assets/img/Bg/bg-3.png";
import tokenlogo from "../Assets/img/Icons/icon-logo.png";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import endpoint from "../Assets/img/admin-icons/endpoint.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UserContest from "../ContextAPI/UserContest";
import {
  CreatePayment,
  GetAllAvaliableCurrencies,
  GetAllAvaliableCurrenciesWithLogo,
  GetSelectedCurrencyPrice,
  GraphTotal,
  MakeTransactionWithAddress,
  UpdateTransaction,
  getBonusTokenCountWithOutStage,
  getSumOfCurrencies,
  getTokenBonusCount,
  getTotalTokenOfwallet,
  getUserDetail,
} from "../Api";
import PurchaseTokenWithWallet from "../Pages/AdminPanel/PurchaseTokenWithWallet";
import iconcard from "../Assets/img/Icons/card.png";
import iconusdc from "../Assets/img/admin-icons/icon-udsc.png";
import icontherium from "../Assets/img/Icons/etherium.png";
import icondollar from "../Assets/img/Icons/coin-dollar.png";
import iconbtc from "../Assets/img/Icons/coin-btc.png";
import frame from "../Assets/img/Icons/Frame.png";
import iconframe from "../Assets/img/Icons/icon-teather.png";
import iconvisacard from "../Assets/img/Icons/icon-visa-card.png";
import iconiconbtc from "../Assets/img/Icons/icon-btc.png";
import Discordison from "../Assets/img/Icons/discord.png";
import { useAccount, useDisconnect } from "wagmi";
import iconcopy from "../Assets/img/admin-icons/icon-copy.png";

import Discord from "../Assets/img/Icons/icon-discord.png";
import {
  BannerVideo,
  CommasRegex,
  CurrencyImageUrl,
  CurrenicesArray,
  DiscordIcon,
  InstagramUrl,
  NumberRegex,
  colorRed,
  colorWhite,
  teleGramUrl,
  twitterIcon,
} from "../Constants";
import { BounceLoader, PulseLoader } from "react-spinners";
import { Notifications } from "./Toast";
import { QRCode } from "react-qrcode-logo";
import { debounce } from "lodash"; // Import the debounce function from lodash or use your own debounce implementation
import { CopyToClipboardButton } from "../Constants/utils";
// import { Promocode } from "../ContextAPI/type";
function BuyToken({ setSignupShow, SignupShow, GetAllTransactionOnPourchase }) {
  const {
    CurrentStage,
    TotalSaleInStage,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
    SetUserDetail,
    SelectedNetwork,
    SelectedCurrency,
    Selectedvalue,
  } = useContext(UserContest);
  const iconsArray = [
    iconcard,
    icontherium,
    icondollar,
    iconbtc,
    frame,
    iconframe,
    iconvisacard,
    iconiconbtc,
  ];
  const [SelectedChain, setSelectedChain] = useState();
  const [BonusToken, setBonusToken] = useState();
  const navigate = useNavigate();
  const [totalToken, settotalToken] = useState(0);
  const [saleGraph, setsaleGraph] = useState();
  const [amount, setAmount] = useState();
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  // const address =?
  const { address, isConnecting, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [percentage, setPercentage] = useState();
  const [promoValidation, setPromoValidation] = useState();
  const [promotionBonus, setPromotionBonus] = useState();
  const [loader, setloader] = useState(false);
  const currencyies = {
    USDT: "usdterc20",
    USDC: "usdcbsc",
    BUSD: "busdbsc",
  };

  const [isOrderPaymentVisible, setOrderPaymentVisibility] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: "BTC",
    value: "btc",
  });

  const [currencies, setCurrencies] = useState([]);
  const [currenciesWithLogo, setCurrenciesWithLogo] = useState([]);
  const [selectLogo, setSelectedLogo] = useState();
  const [amountInCurrency, setAmountInCurrency] = useState(3);
  const [selectedCurrencyPrice, setselectedCurrencyPrice] = useState(0);
  const [agreed, setagreed] = useState(true);
  const [transactionHash, setTransactionHash] = useState();
  const [toAddress, setToAddress] = useState();
  const [ExpireyPayment, setExpireyPayment] = useState();
  const [PromoCodetext, setPromoCodetext] = useState("");
  const [PromoCodetextchange, sePromoCodetextchange] = useState("");
  const [ExtraBonus, setExtraBonus] = useState(false);
  const [AmountToSend, setAmountToSend] = useState();
  const [isFormVisible, setFormVisibility] = useState(false);
  const [isStep2Visible, setIsStep2Visible] = useState(false);
  const [validationError, setValidationError] = useState("");

  const currencyNameBNB = "bnbbsc";

  useEffect(() => {
    if (SelectedNetwork?.includes("BNB")) {
      setSelectedCurrency({
        value: "bnbbsc",
      });
    } else if (SelectedNetwork?.includes("ETH")) {
      setSelectedCurrency({
        value: "eth",
      });
    } else if (SelectedNetwork?.includes("BUSD")) {
      setSelectedCurrency({
        value: "busd",
      });
    } else if (SelectedNetwork?.includes("USDC")) {
      setSelectedCurrency({
        value: "usdc",
      });
    } else if (SelectedNetwork?.includes("USDT")) {
      setSelectedCurrency({
        value: "usdterc20",
      });
    }
    // console.log( currencyies[SelectedNetwork] , 'currencyies[SelectedNetwork]')
    // console.log(SelectedNetwork,'SelectedNetwork' )
  }, [SelectedNetwork]);
  useEffect(() => {
    // console.log(selectedCurrency)

    setSelectedLogo(
      currenciesWithLogo?.find((item) => item.ticker === selectedCurrency.value)
    );
    // console.log(currenciesWithLogo?.find((item) => item.ticker == selectedCurrency.value))

    GetSelectedCurrencyPrice(
      selectedCurrency?.value === "bnb"
        ? currencyNameBNB
        : selectedCurrency?.value
    )
      .then((price) => {
        // console.log(price,'price')
        setselectedCurrencyPrice(price);
      })
      .catch(() => {});
  }, [selectedCurrency, currenciesWithLogo]);

  const Network = {
    mainNetwork: "mainNetwork",
    BUSD: "BUSD",
    USDT: "USDT",
    USDC: "USDC",
  };
  useEffect(() => {
    GraphTotal()
      .then((data) => {
        // console.log('GraphTotal',data.data.totalraised )
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => {});
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // console.log('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased));
        })
        .catch(() => {});
    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased));
      })
      .catch(() => {});
    getUserDetail()
      .then((detail) => {
        // console.log(detail ,'getUserDetail' )
        // navigate("/Dashboard");
        SetUserDetail(detail);
      })
      .catch(() => {
        if (localStorage.getItem("JwtToken")) {
          localStorage.clear();
        }
        // navigate('/')
      });
    let percentage =
      (parseInt(saleGraph) / parseInt(CurrentStage?.total_limit)) * 100;
    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)
    let remaining = parseInt(
      parseInt(CurrentStage?.total_limit) -
        (parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken) >
        parseInt(CurrentStage?.total_limit)
          ? parseInt(CurrentStage?.total_limit)
          : parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken))
    );
    let sold = parseInt(parseInt(TotalSaleInStage) + parseInt(BonusToken));
    setPercentage(parseFloat((sold / (remaining + sold)) * 100).toFixed(2));
    // setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);
  const DefaultChain = {
    ETH: "ETH",
    BNB: "BNB",
  };
  useEffect(() => {
    if (address)
      getTotalTokenOfwallet(address)
        .then((totlatoke) => {
          settotalToken(totlatoke.data ?? 0);
        })
        .catch(() => {});
  }, [address]);
  const [TotalRaised, setTotalRaised] = useState();
  useEffect(() => {
    let sum = 0;
    getSumOfCurrencies()
      .then((res) => {
        setTotalRaised(res.data.totalraised);
      })
      .catch(() => {});
  }, []);
  const bgVideoRef = useRef(null);
  const videoSource = BannerVideo; // Replace with the actual path to your video file
  useEffect(() => {
    // Set the playback rate to 0.5 for half speed (adjust as needed)
    if (bgVideoRef.current) {
      bgVideoRef.current.playbackRate = 0.5;
    }
  }, []);
  const [currentIconIndex, setCurrentIconIndex] = useState(0);
  useEffect(() => {
    // Set up an interval to change the icon every 2 seconds
    const intervalId = setInterval(() => {
      // Update the current icon index
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % iconsArray.length);
    }, 2000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once on mount
  useEffect(() => {
    if (SelectedCurrency && Selectedvalue) {
      setAmount(Selectedvalue);
      setSelectedCurrency(SelectedCurrency);
    }

    GetAllAvaliableCurrencies()
      .then((currencies) => {
        // console.log(currencies)
        setCurrencies(currencies.currencies);
      })
      .catch(() => {});
    GetAllAvaliableCurrenciesWithLogo()
      .then((currencies) => {
        setCurrenciesWithLogo(currencies);
        // console.log(currencies, 'currencies')
      })
      .catch(() => {});
  }, []);
  const calculateTokens = () => {
    const tokensPerDollar = 1 / CurrentStage?.price_of_token;
    const numberOfTokens = tokensPerDollar * (amount ?? 0);
    return numberOfTokens;
  };
  const calculateBTC = () => {
    // console.log(selectedCurrencyPrice, 'selectedCurrencyPrice')
    const btcPerUSD = (amount ?? 0) * selectedCurrencyPrice;
    // const btcAmount = btcPerUSD * ;
    return btcPerUSD.toFixed(5);
  };
  const options = CurrenicesArray?.map((item) => ({
    label: item.label.toUpperCase(),
    image: item.image,
    value: item.label,
  }));

  const tokens = calculateTokens();
  const btcReceived = calculateBTC();
  const toggleFormVisibility = () => {
    setFormVisibility(!isFormVisible);
  };
  function calculateBonus(depositAmount) {
    let bonusPercentage = 0;

    if (depositAmount >= 1000.0 && depositAmount <= 5000) {
      bonusPercentage = 0.05;
    } else if (depositAmount >= 5000.0 && depositAmount <= 25000) {
      bonusPercentage = 0.1;
    } else if (depositAmount >= 25000.0 && depositAmount <= 100000) {
      bonusPercentage = 0.125;
    } else if (depositAmount >= 100000) {
      bonusPercentage = 0.15;
    }

    return bonusPercentage;
  }

  const bonus = calculateBonus(amount);

  const handleConfirm = (e) => {
    e.preventDefault();
    if (amount && parseFloat(amount) > 0) {
      setIsStep2Visible(true);
    }
  };
  const toggleOrderPaymentVisibility = () => {
    if (
      parseInt(CurrentStage?.total_limit) - parseInt(TotalSaleInStage ?? 0) <
      tokens
    ) {
      Notifications("error", "Token value increased from limit");
      return;
    }
    setloader(true);
    if (amount > 0 && agreed) {
      const Body = {
        address: address,
        token_purchased: tokens,
        amount_in_crypto: btcReceived,
        amount_in_dollars: amount,
        selected_crypto: selectedCurrency.value,
        stage_id: CurrentStage?.id,
        type: "Purchase",
        bonus_token: tokens * (bonus ?? 0),
        promotionText: PromoCodetext,
      };

      MakeTransactionWithAddress(Body)
        .then((data) => {
          CreatePayment({
            amount: amount,
            selectedCurrency: selectedCurrency.value,
            transaction_number: data[0].transaction_number,
            description: btoa(
              JSON.stringify({
                stage_id: CurrentStage?.id,
                address: address,
                created_from_referral: false,
                referral_used: false,
              })
            ),
          })
            .then((createdPayment) => {
              setloader(false);
              setToAddress(createdPayment.pay_address);
              setAmountToSend(createdPayment.pay_amount);
              setExpireyPayment(createdPayment.expiration_estimate_date);

              let updateTransaction = {
                expiry_date: createdPayment.expiration_estimate_date,
                payment_id: createdPayment.payment_id,
                toAddress: createdPayment.pay_address,
                transaction_number: data[0].transaction_number,
                payment_created_date: createdPayment.created_at,
                AmountToSend: createdPayment.pay_amount,
              };
              UpdateTransaction(updateTransaction)
                .then(() => {
                  GetAllTransactionOnPourchase();
                })
                .catch(() => {
                  setloader(false);
                });
            })
            .catch(() => {
              setloader(false);
            });

          setTransactionHash(data[0].transaction_number);
          setOrderPaymentVisibility(!isOrderPaymentVisible);
        })
        .catch(() => {
          setloader(false);
          Notifications("error", "Some error occured during purchase");
        });
    } else {
      setloader(false);
      if (!amount) Notifications("error", "Please Enter Amount");
      else if (!agreed)
        Notifications("error", "Please check  term and conditions");
    }
  };
  const debouncedClickHandler = debounce(() => {
    if (!loader) {
      toggleOrderPaymentVisibility();
    }
  }, 1000);
  return (
    <>
      <Container fluid className="stage-container-panel">
        <Row>
          <Container className="zindex">
            <div className="stage-container-panel-inner">
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${sunbg})` }}
              ></div>
              <Row>
                <Col
                  xl="12"
                  lg="12"
                  md="12"
                  style={
                    !isDisconnected && !isConnecting ? { display: "none" } : {}
                  }
                  className="text-center"
                >
                  <div className="stage-1">
                    <div>
                      <h3 className="mt-0 font-weight-bold text-uppercase chakra">
                        {CurrentStage?.level}{" "}
                      </h3>
                    </div>
                    <div
                      className="stage-post"
                      onClick={() => {
                        setSignupShow(true);
                      }}
                    >
                      <div className="img-pnl">
                        {/* <CircularProgressbar
                      value={percentage / 100}
                      maxValue={1}
                      text={`${percentage}%`}
                    /> */}
                        {/* <img src={sold} alt="Data" /> */}
                        <ul className="bonus-percentage-list full">
                          <li className="circle-heading">
                            <h1>
                              <span className="f-18 w-100 d-inline-block Titillium">
                                Sold
                              </span>
                              <div className="d-flex">{percentage} %</div>
                              {/* 35.21% */}
                            </h1>
                          </li>
                        </ul>
                      </div>
                      <div className="txt-pnl">
                        <ul className="my-token-list w-100 trans">
                          <li>
                            <p>Tokens Sold In Total:</p>
                            <div className="border-span" />
                            <p>
                              <span>
                                {(
                                  parseInt(OverAllSaleToken) +
                                  parseInt(bonusWithoutStage)
                                )
                                  ?.toString()
                                  .replace(CommasRegex, ",") ?? 0}
                              </span>
                            </p>
                          </li>
                          <li>
                            <p>Total Raised:</p>
                            <div className="border-span" />
                            <p>
                              <span>
                                ${" "}
                                {(
                                  (parseInt(OverAllSaleToken) +
                                    parseInt(bonusWithoutStage)) *
                                  0.04
                                )
                                  .toFixed(3)
                                  ?.toString()
                                  .replace(CommasRegex, ",") ?? 0}
                              </span>
                            </p>
                          </li>
                          <li>
                            <div className="progress-bar">
                              <div
                                className="bar"
                                style={{ width: `${percentage}%` }}
                              >
                                <ProgressBar
                                  className="custom-progress-bar"
                                  now={percentage / 100}
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                        <ul className="dark-list web-view-md">
                          <li>
                            <p>
                              <img src={tokenlogo} alt="Token" /> Tokens
                              remaining in stage:
                            </p>
                            <p>
                              <b>
                                {parseFloat(
                                  parseInt(
                                    parseInt(CurrentStage?.total_limit) -
                                      (parseInt(TotalSaleInStage ?? 0) +
                                        parseInt(BonusToken) >
                                      parseInt(CurrentStage?.total_limit)
                                        ? parseInt(CurrentStage?.total_limit)
                                        : parseInt(TotalSaleInStage ?? 0) +
                                          parseInt(BonusToken))
                                  )
                                )
                                  ?.toString()
                                  .replace(CommasRegex, ",")}

                                {/* <span>
                     {
                     parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                     }
                        </span> */}
                              </b>
                            </p>
                          </li>
                          <li>
                            <p>$NDFT price in current stage:</p>
                            <p>
                              <b>
                                {CurrentStage?.price_of_token}{" "}
                                <img src={icontether} alt="tether" />
                              </b>
                            </p>
                          </li>
                          <li>
                            <p>Token price in the next stage:</p>
                            <p>
                              <b>{CurrentStage?.futuretokenprice}</b>{" "}
                              <img src={icontether} alt="tether" />
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tablet-view">
                      <ul className="dark-list">
                        <li>
                          <p>
                            <img src={tokenlogo} alt="Token" /> Tokens remaining
                            in stage:
                          </p>
                          <p>
                            <b>
                              {parseFloat(
                                parseInt(
                                  parseInt(CurrentStage?.total_limit) -
                                    (parseInt(TotalSaleInStage ?? 0) +
                                      parseInt(BonusToken) >
                                    parseInt(CurrentStage?.total_limit)
                                      ? parseInt(CurrentStage?.total_limit)
                                      : parseInt(TotalSaleInStage ?? 0) +
                                        parseInt(BonusToken))
                                )
                              )
                                ?.toString()
                                .replace(CommasRegex, ",")}

                              {/* <span>
                     {
                     parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                     }
                        </span> */}
                            </b>
                          </p>
                        </li>
                        <li>
                          <p>$NDFT price in current stage:</p>
                          <p>
                            <b>
                              {CurrentStage?.price_of_token}{" "}
                              <img src={icontether} alt="tether" />
                            </b>
                          </p>
                        </li>
                        <li>
                          <p>Token price in the next stage:</p>
                          <p>
                            <b>{CurrentStage?.futuretokenprice}</b>{" "}
                            <img src={icontether} alt="tether" />
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="text-center">
                      <Link
                        href="#"
                        className="reg-btn br-radius"
                        onClick={() => {
                          setSignupShow(true);
                        }}
                      >
                        Buy $NDF Token Now
                      </Link>
                    </div>
                  </div>

                  {/* Talha */}
                  {/* Step 1 */}
                  <div className="step-1 full-div displayNone">
                    <h3 className="mt-0 text-center font-weight-bold text-uppercase chakra">
                      Step 1
                    </h3>
                    <div className="spacer-10" />
                    <ul className="my-token-list w-100 trans p-0">
                      <li>
                        <div className="progress-bar">
                          <div
                            className="bar"
                            style={{ width: `${percentage}%` }}
                          >
                            <ProgressBar
                              className="custom-progress-bar"
                              now={percentage / 100}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div className="spacer-10" />
                    <p className="f-18 text-left lightgrey-color">
                      Enter the amount in USD you want spend to purchase $NDF
                      tokens.
                    </p>
                    <div className="spacer-20" />
                    <Form className="text-left-fix">
                      <div className="flex-div-sm">
                        <Form.Group
                          controlId="formBasicEmail"
                          className="w-100 text-left-fix"
                        >
                          <Form.Label>
                            <i>Amount is USD</i>
                          </Form.Label>
                          <Form.Control type="number" placeholder="0.00" />
                        </Form.Group>

                        <Button
                          className="reg-btn br-radius confirm-btn"
                          type="submit"
                        >
                          Confirm
                        </Button>
                      </div>
                    </Form>
                    <div className="spacer-20" />
                    <ul className="bonus-percentage-list">
                      <li>
                        <h3>
                          <span>Bonus</span>
                          5%
                        </h3>
                        <p>
                          Deposit
                          <span>250.00</span>
                          USD to <span>1999.99</span> USD
                        </p>
                      </li>
                      <li>
                        <h3>
                          <span>Bonus</span>
                          10%
                        </h3>
                        <p>
                          Deposit
                          <span>200.00</span>
                          USD to <span>4999.99</span> USD
                        </p>
                      </li>
                      <li>
                        <h3>
                          <span>Bonus</span>
                          20%
                        </h3>
                        <p>
                          Deposit over
                          <span>500.00</span>
                        </p>
                      </li>
                    </ul>

                    <div className="spacer-20" />
                    {/* <p className="f-20 text-left lightgrey-color m-0">
                    Connected Wallet <b className="lighter-yellow">0x31…sa18</b>{" "}
                    <Link href="#" className="darkbluecolor text-decor">
                      Transactions history
                    </Link>
                  </p> */}
                  </div>
                  {/* Step 1 */}

                  {/* Talha */}
                  {/* Step 2 */}
                  <div className="step-2 full-div displayNone">
                    <h3 className="mt-0 text-center font-weight-bold text-uppercase chakra">
                      Step 2
                    </h3>
                    <div className="spacer-10" />
                    <ul className="my-token-list w-100 trans p-0">
                      <li>
                        <div className="progress-bar">
                          <div
                            className="bar"
                            style={{ width: `${percentage}%` }}
                          >
                            <ProgressBar
                              className="custom-progress-bar"
                              now={percentage / 100}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="spacer-10" />
                    <p className="f-18 text-left lightgrey-color">
                      Select your payment currency
                    </p>
                    <Form className="text-left-fix">
                      <Row>
                        <Col xl="8" lg="8" md="7">
                          <Form.Group
                            controlId="formBasicEmail"
                            className="w-100 text-left-fix mb-3"
                          >
                            <Form.Label>
                              <i>You send</i>
                            </Form.Label>
                            <Form.Control type="number" placeholder="0.00" />
                          </Form.Group>
                        </Col>
                        <Col xl="4" lg="4" md="5">
                          <Form.Group
                            controlId="formBasicEmail"
                            className="w-100 text-left-fix mb-3"
                          >
                            <Form.Label>
                              <i>Select a currency</i>
                            </Form.Label>
                            <Form.Select size="lg" className="form-control">
                              <option>BNB</option>
                              <option>ETCH</option>
                              <option>BTC</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button className="simple-link purple">
                        Add Promo Code
                      </Button>
                      <div className="spacer-20" />
                      <div>
                        <Row>
                          <Col xl="9" lg="9" md="8" sm="12">
                            <Form>
                              <div
                                className="input-cntnr"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  className="input-promocode"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Enter PROMO CODE here"
                                />
                              </div>
                            </Form>
                          </Col>
                          <Col xl="3" lg="3" md="4" sm="12">
                            <Link className="reg-btn w-100 br-radius empty text-center small">
                              Apply Promo code
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                    <div className="spacer-20" />
                    <ul className="my-token-list w-100">
                      <li>
                        <p className="m-0">Tokens Ordered:</p>
                        <p className="m-0">
                          <b className="notranslate">
                            138,258.12
                            <span className="lightgrey-color ml-2 font-weight-normal">
                              $NDFT
                            </span>
                          </b>
                        </p>
                      </li>
                      <li>
                        <p className="m-0">Purchase Bonus:</p>
                        <p className="m-0">
                          <b className="notranslate">
                            6,912.91
                            <span className="lightgrey-color ml-2 font-weight-normal">
                              $NDFT
                            </span>
                          </b>
                        </p>
                      </li>

                      <li>
                        <p className="m-0">Extra Bonus:</p>
                        <p className="m-0">
                          0{" "}
                          <b className="notranslate">
                            <span className="lightgrey-color ml-2 font-weight-normal">
                              $NDFT
                            </span>
                          </b>
                        </p>
                      </li>
                    </ul>
                    <div className="spacer-30" />
                    <div className="text-center">
                      <h4 className="chakra">
                        <b>
                          <img src={arrowleft} alt="Arrow Left" /> You Get:{" "}
                          <img src={arrowright} alt="Arrow Right" />
                        </b>
                      </h4>
                      <h3 className=" token-text-cntnr notranslate chakra">
                        <span>
                          <img
                            className="mr-2"
                            src={iconlogo}
                            alt="ALGo Token"
                          />
                          14,171.03
                          <span className="font-weight-bold tillium ml-2 f-18">
                            $NDFT
                          </span>
                        </span>
                        <span className="mx-3 f-18">≈</span>
                        <span>
                          <img
                            className="mr-2"
                            style={{ width: "30px" }}
                            src={icontether1}
                            alt="USDC Token"
                          />
                          1000.00
                          <span className="font-weight-bold tillium ml-2 f-18">
                            USDT
                          </span>
                        </span>
                      </h3>
                    </div>
                    <div className="spacer-20" />
                    <p className="f-20 text-left lightgrey-color m-0">
                      Connected Wallet{" "}
                      <b className="lighter-yellow">0x31…sa18</b>{" "}
                      <Link href="#" className="darkbluecolor text-decor">
                        Transactions history
                      </Link>
                    </p>
                  </div>
                  {/* Step 2 */}

                  {/* Talha */}
                  {/* Congratulation */}
                  <div className=" full-div Congratulations displayNone">
                    <h2 className="mt-0 text-center font-weight-bold text-uppercase chakra">
                      Congratulations!
                    </h2>
                    <h4 className="mt-0 text-center font-weight-bold chakra">
                      Your balance is
                    </h4>
                    <div className="text-center">
                      <h3 className=" token-text-cntnr notranslate chakra">
                        <span>
                          <img
                            className="mr-2"
                            src={iconlogo}
                            alt="ALGo Token"
                          />
                          14,171.03
                          <span className="font-weight-bold tillium ml-2 f-18">
                            $NDFT
                          </span>
                        </span>
                      </h3>
                    </div>
                    <div className="spacer-20" />
                    <p className="f-20 text-left lightgrey-color m-0">
                      Connected Wallet{" "}
                      <b className="lighter-yellow">0x31…sa18</b>{" "}
                      <Link href="#" className="darkbluecolor text-decor">
                        Transactions history
                      </Link>
                    </p>
                  </div>
                  {/* Congratulation */}
                </Col>
                <Col
                  xl="12"
                  lg="12"
                  md="12"
                  className="text-center displayNone"
                  style={
                    !isDisconnected && !isConnecting
                      ? { display: "initial" }
                      : {}
                  }
                >
                  {/* <div>
                  <h3 className="mt-0 font-weight-bold text-uppercase chakra">
                    {CurrentStage?.level}{" "}
                  </h3>
                </div>
                <div
                  className="stage-post"
                  onClick={() => {
                    setSignupShow(true);
                  }}
                >
                  <div className="img-pnl">
                   
                    <ul className="bonus-percentage-list full">
                      <li className="circle-heading">
                        <h1>
                          <span className="f-18 w-100 d-inline-block Titillium">
                            Sold
                          </span>
                          <div className="d-flex">{percentage} %</div>
                        </h1>
                      </li>
                    </ul>
                  </div>
                  <div className="txt-pnl">
                    <ul className="my-token-list w-100 trans">
                      <li>
                        <p>Tokens Sold In Total:</p>
                        <div className="border-span"/>
                        <p>
                          <span>
                            {(
                              parseInt(OverAllSaleToken) +
                              parseInt(bonusWithoutStage)
                            )
                              ?.toString()
                              .replace(CommasRegex, ",") ?? 0}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>Total Raised:</p>
                        <div className="border-span"/>
                        <p>
                          <span>
                            ${" "}
                            {(
                              (parseInt(OverAllSaleToken) +
                                parseInt(bonusWithoutStage)) *
                              0.04
                            )
                              .toFixed(3)
                              ?.toString()
                              .replace(CommasRegex, ",") ?? 0}
                          </span>
                        </p>
                      </li>
                      <li>
                        <div className="progress-bar">
                          <div
                            className="bar"
                            style={{ width: `${percentage}%` }}
                          >
                            <ProgressBar
                              className="custom-progress-bar"
                              now={percentage / 100}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul className="dark-list web-view-md">
                      <li>
                        <p>
                          <img src={tokenlogo} alt="Token" /> Tokens remaining
                          in stage:
                        </p>
                        <p>
                          <b>
                            {parseFloat(
                              parseInt(
                                parseInt(CurrentStage?.total_limit) -
                                  (parseInt(TotalSaleInStage ?? 0) +
                                    parseInt(BonusToken) >
                                  parseInt(CurrentStage?.total_limit)
                                    ? parseInt(CurrentStage?.total_limit)
                                    : parseInt(TotalSaleInStage ?? 0) +
                                      parseInt(BonusToken))
                              )
                            )
                              ?.toString()
                              .replace(CommasRegex, ",")}
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>$NDFT price in current stage:</p>
                        <p>
                          <b>
                            {CurrentStage?.price_of_token}{" "}
                            <img src={icontether} alt="tether" />
                          </b>
                        </p>
                      </li>
                      <li>
                        <p>Token price in the next stage:</p>
                        <p>
                          <b>{CurrentStage?.futuretokenprice}</b>{" "}
                          <img src={icontether} alt="tether" />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-center">
                  <Link href="#" className="reg-btn br-radius">
                    Buy $NDF Token Now
                  </Link>
                  <div className="spacer-30" />
                </div> */}
                  {!isStep2Visible ? (
                    <div className="step-1 full-div">
                      <h3 className="mt-0 text-center font-weight-bold text-uppercase chakra">
                        Step 1
                      </h3>
                      <div className="spacer-10" />
                      <ul className="my-token-list w-100 trans p-0">
                        <li>
                          <div className="progress-bar">
                            <div
                              className="bar"
                              style={{ width: `${percentage}%` }}
                            >
                              <ProgressBar
                                className="custom-progress-bar"
                                now={percentage / 100}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="spacer-10" />
                      <p className="f-18 text-left lightgrey-color">
                        Enter the amount in USD you want spend to purchase $NDF
                        tokens.
                      </p>
                      <div className="spacer-20" />
                      <Form className="text-left-fix">
                        <div className="flex-div-sm">
                          <Form.Group
                            controlId="exampleForm.ControlInput1"
                            className="w-100 text-left-fix"
                          >
                            <Form.Label>Amount in USD </Form.Label>
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow only numbers from 0 to 9, an optional single dot, and an empty string (for backspace)
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setAmount(inputValue);
                                }
                              }}
                              onKeyPress={(e) => {
                                // Allow only numbers from 0 to 9, an optional single dot, and backspace
                                const keyCode = e.keyCode || e.which;
                                const keyValue = String.fromCharCode(keyCode);
                                if (
                                  !NumberRegex.test(keyValue) &&
                                  keyValue !== "." &&
                                  keyCode !== 8
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              value={amount}
                              className="input-dollar"
                              placeholder="Amount in USD"
                            />
                          </Form.Group>

                          <Button
                            className="reg-btn br-radius confirm-btn"
                            onClick={handleConfirm}
                            disabled={
                              loader ||
                              parseFloat(selectedCurrencyPrice) === 0 ||
                              promoValidation ||
                              parseFloat(amount) >
                                parseFloat(CurrentStage.maximumpurchase) ||
                              !amount ||
                              amount == 0
                            }
                          >
                            Confirm
                          </Button>
                        </div>
                        <br />

                        {parseInt(amount) >
                        parseInt(CurrentStage?.maximumpurchase) ? (
                          <p style={{ color: colorRed }}>
                            Maximum Purchase of token is $
                            {parseInt(CurrentStage?.maximumpurchase)}
                          </p>
                        ) : (
                          <>
                            {parseInt(CurrentStage?.total_limit) -
                              (parseInt(TotalSaleInStage) || 0) <
                              tokens && (
                              <p style={{ color: colorRed }}>
                                Token Limit in Current Stage Exceeds:
                              </p>
                            )}
                          </>
                        )}
                      </Form>
                      <div className="spacer-20" />
                      <ul className="bonus-percentage-list">
                        <li>
                          <h3>
                            <span>Bonus</span>
                            5%
                          </h3>
                          <p>
                            Deposit
                            <span>250.00</span>
                            USD to <span>1999.99</span> USD
                          </p>
                        </li>
                        <li>
                          <h3>
                            <span>Bonus</span>
                            10%
                          </h3>
                          <p>
                            Deposit
                            <span>200.00</span>
                            USD to <span>4999.99</span> USD
                          </p>
                        </li>
                        <li>
                          <h3>
                            <span>Bonus</span>
                            20%
                          </h3>
                          <p>
                            Deposit over
                            <span>500.00</span>
                          </p>
                        </li>
                      </ul>

                      <div className="spacer-20" />
                      <p className="f-20 text-left lightgrey-color m-0">
                        Connected Wallet{" "}
                        <b className="lighter-yellow">
                          {address?.slice(0, 5) + "..." + address?.slice(-5)}
                        </b>{" "}
                        <a
                          className="darkbluecolor text-decor"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/walletTransactions/transaction");
                          }}
                          to={void 0}
                        >
                          Transactions history
                        </a>
                      </p>
                    </div>
                  ) : (
                    <div className="step-2 full-div">
                      {!isOrderPaymentVisible ? (
                        <>
                          <h3 className="mt-0 text-center font-weight-bold text-uppercase chakra">
                            Step 2
                          </h3>
                          <ul className="my-token-list w-100 trans p-0">
                            <li>
                              <div className="progress-bar">
                                <div
                                  className="bar"
                                  style={{ width: `${percentage}%` }}
                                >
                                  <ProgressBar
                                    className="custom-progress-bar"
                                    now={percentage / 100}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="spacer-10" />
                          <p className="f-18 text-left lightgrey-color">
                            Select your payment currency
                          </p>
                          <>
                            <Form className="text-left-fix">
                              <Row>
                                <Col xl="8" lg="8" md="7">
                                  <Form.Group
                                    className="w-100 text-left-fix mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>
                                      <i>You send</i>
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      disabled={true}
                                      value={btcReceived}
                                      autoComplete="new-email"
                                      className="input-dollar"
                                      placeholder="Enter Your Email"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xl="4" lg="4" md="5">
                                  <Form.Group
                                    className="w-100 text-left-fix mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>
                                      Select Payment Currency
                                    </Form.Label>
                                    <Form.Select
                                      onChange={(e) => {
                                        if (e.target.value !== "DUMP")
                                          setSelectedCurrency(
                                            options.find(
                                              (item) =>
                                                item.value === e.target.value
                                            )
                                          );
                                      }}
                                      className="form-control "
                                      aria-label="Default select example"
                                      value={selectedCurrency.value}
                                    >
                                      <option value={"DUMP"}>
                                        -- Select Token --
                                      </option>
                                      {options.map((item, index) => {
                                        return (
                                          <option value={item.value}>
                                            {item.label.toUpperCase()}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Form>
                          </>
                          <div className="text-left-fix">
                            {
                              <Button
                                className={`simple-link purple  `}
                                onClick={() => {
                                  if (PromoCodetext?.length > 1) {
                                    setPromoCodetext(null);
                                    sePromoCodetextchange("");
                                    setPromoValidation("");
                                  } else {
                                    toggleFormVisibility();
                                  }
                                }}
                              >
                                {PromoCodetext?.length > 1
                                  ? "remove  Promo Code"
                                  : "Add  Promo Code"}
                              </Button>
                            }
                            {PromoCodetext?.length > 1 && (
                              <>
                                <p>PromoCode : {PromoCodetext}</p>
                                <p style={{ color: colorRed }}>
                                  {promoValidation}
                                </p>
                              </>
                            )}
                            <div className="spacer-20" />
                          </div>

                          <div
                            className={`full-div ${
                              isFormVisible ? "" : "hidden"
                            }`}
                          >
                            <Row>
                              <Col xl="9" lg="9" md="8" sm="12">
                                <Form>
                                  <div
                                    className="input-cntnr"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Control
                                      className="input-promocode"
                                      autoComplete="off"
                                      type="text"
                                      value={PromoCodetextchange}
                                      onChange={(e) => {
                                        sePromoCodetextchange(e.target.value);
                                      }}
                                      placeholder="Enter PROMO CODE here"
                                    />
                                  </div>
                                </Form>
                              </Col>
                              <Col xl="3" lg="3" md="4" sm="12">
                                <Link
                                  className="reg-btn w-100 br-radius empty text-center small"
                                  to={void 0}
                                  onClick={() => {
                                    toggleFormVisibility();
                                    setPromoCodetext(PromoCodetextchange);
                                  }}
                                >
                                  Apply Code
                                </Link>
                              </Col>
                            </Row>
                          </div>
                          <div className="spacer-20" />
                          <ul className="my-token-list w-100">
                            <li>
                              <p className="m-0">Tokens Ordered:</p>
                              <p className="m-0 ">
                                <b className="notranslate">
                                  {parseFloat(tokens)
                                    .toFixed(2)
                                    ?.toString()
                                    .replace(CommasRegex, ",") ?? 0}{" "}
                                  <span className="lightgrey-color ml-2 font-weight-normal">
                                    $NDFT
                                  </span>
                                </b>
                              </p>
                            </li>
                            <li>
                              <p className="m-0">Purchase Bonus:</p>
                              <p className="m-0 ">
                                <b className="notranslate">
                                  {parseFloat(tokens * bonus)
                                    .toFixed(2)
                                    ?.toString()
                                    .replace(CommasRegex, ",") ?? 0}{" "}
                                  <span className="lightgrey-color ml-2 font-weight-normal">
                                    $NDFT
                                  </span>
                                </b>
                              </p>
                            </li>
                            <li>
                              <p className="m-0">Extra Bonus:</p>
                              <p className="m-0 ">
                                <b className="notranslate">
                                  {" "}
                                  {ExtraBonus ? tokens * 0.1 : 0}
                                  <span className="lightgrey-color ml-2 font-weight-normal">
                                    $NDFT
                                  </span>
                                </b>
                              </p>
                            </li>
                          </ul>
                          <div className="spacer-30" />
                          <div className="text-center">
                            <h4 className="chakra">
                              <b>
                                <img src={arrowleft} alt="Arrow Left" /> You
                                Get: <img src={arrowright} alt="Arrow Right" />
                              </b>
                            </h4>
                            <h3 className=" token-text-cntnr notranslate chakra">
                              <img
                                className="mr-2"
                                src={iconlogo}
                                alt="ALGo Token"
                              />
                              <span>
                                {parseFloat(tokens)
                                  .toFixed(2)
                                  ?.toString()
                                  .replace(CommasRegex, ",") ?? 0}
                                <span className="font-weight-normal ml-2 f-26">
                                  $NDFT
                                </span>
                              </span>
                              <span className="mx-3 f-18">≈</span>
                              <span>
                                <img
                                  className="mr-2"
                                  style={{ width: "30px" }}
                                  src={
                                    selectLogo?.logo_url
                                      ? `${CurrencyImageUrl}${selectLogo?.logo_url}`
                                      : iconusdc
                                  }
                                  alt="USDC Token"
                                />
                                {btcReceived}
                                <span className="font-weight-bold tillium ml-2 f-18">
                                  {selectedCurrency.value?.toUpperCase()}{" "}
                                </span>
                              </span>
                            </h3>
                          </div>
                          <div className="spacer-20" />
                          <div className="text-center">
                            <Form>
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`}>
                                  <Form.Check
                                    className="checkbox-inline"
                                    checked={agreed}
                                    onChange={(e) =>
                                      setagreed(e.target.checked)
                                    } // Corrected event handler
                                    inline
                                    // label={
                                    //   <>
                                    //     I hereby agree to the{" "}
                                    //     <Link
                                    //       to="/TermsConditions"
                                    //       target="_blank" // This opens the link in a new tab/window
                                    //       rel="noopener noreferrer" // Recommended for security reasons
                                    //     >
                                    //       payment agreement and token sale terms *
                                    //     </Link>
                                    //   </>
                                    // }
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                  />
                                </div>
                              ))}
                            </Form>
                            {/* <div className="spacer-20" /> */}
                            <Button
                              className={`reg-btn br-radius letter-spacing-1 ${
                                isOrderPaymentVisible ? "" : "remove"
                              }`}
                              disabled={
                                loader ||
                                parseFloat(selectedCurrencyPrice) === 0 ||
                                promoValidation ||
                                parseFloat(amount) >
                                  parseFloat(CurrentStage.maximumpurchase) ||
                                !amount ||
                                amount == 0
                              }
                              onClick={debouncedClickHandler}
                            >
                              {loader ? (
                                <PulseLoader size={11} color={colorWhite} />
                              ) : (
                                " Buy Token NOW"
                              )}
                            </Button>
                          </div>
                          <div className="spacer-20" />
                          <p className="f-20 text-left lightgrey-color m-0">
                            Connected Wallet{" "}
                            <b className="lighter-yellow">
                              {address?.slice(0, 5) +
                                "..." +
                                address?.slice(-5)}
                            </b>{" "}
                            <a
                              className="darkbluecolor text-decor"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate("/walletTransactions/transaction");
                              }}
                              to={void 0}
                            >
                              Transactions history
                            </a>
                          </p>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col
                              xl={{ span: 8, offset: 2 }}
                              className={`full-div ${
                                isOrderPaymentVisible ? "" : "hidden"
                              }`}
                            >
                              <>
                                <div className="text-center">
                                  <h2 className=" font-weight-bolder">
                                    <img src={arrowleft} alt="Arrow Left" />{" "}
                                    Make your payment{" "}
                                    <img src={arrowright} alt="Arrow Right" />
                                  </h2>
                                </div>
                                <div className="spacer-20" />
                                <div className="blue-post p-0">
                                  <div className="green-post">
                                    <h6 className="m-0">
                                      Your Order #{" "}
                                      <span className="blue-color">
                                        {transactionHash}
                                      </span>{" "}
                                      has been placed successfully!
                                    </h6>
                                  </div>
                                  <div className="inner-padding">
                                    <Row>
                                      <Col
                                        xl="4"
                                        lg="4"
                                        md="12"
                                        className="text-center"
                                      >
                                        <div className="spacer-5" />
                                        {toAddress ? (
                                          <QRCode
                                            value={toAddress}
                                            logoImage={`${CurrencyImageUrl}${selectLogo?.logo_url}`}
                                            padding={0}
                                            size={200}
                                            logoOpacity={1}
                                            logoWidth={50}
                                          />
                                        ) : (
                                          <div className="center-allign">
                                            <BounceLoader
                                              size={40}
                                              color={colorWhite}
                                            />
                                          </div>
                                        )}

                                        <div className="spacer-20" />
                                      </Col>
                                      <Col xl="8" lg="8" md="12">
                                        <p className="m-0">
                                          <b>Amount</b>
                                        </p>
                                        <h4 className="font-weight-bold chakra">
                                          <img
                                            style={{ width: "30px" }}
                                            src={
                                              selectLogo?.logo_url
                                                ? `${CurrencyImageUrl}${selectLogo?.logo_url}`
                                                : iconusdc
                                            }
                                            alt="USDC Token"
                                          />
                                          {AmountToSend}
                                          <span className="font-weight-normal mx-2 Titillium">
                                            {selectedCurrency.value?.toUpperCase()}{" "}
                                          </span>
                                        </h4>
                                        <div className="purple-post">
                                          <p className="m-0 font-weight-bold">
                                            To this address
                                          </p>
                                          <p className="font-weight-normal m-0 lightgrey-color addressTo">
                                            {toAddress ?? (
                                              <PulseLoader
                                                color={colorWhite}
                                                size={11}
                                              />
                                            )}
                                          </p>
                                          {toAddress && (
                                            <Link
                                              onClick={() =>
                                                CopyToClipboardButton(
                                                  toAddress,
                                                  "Address Copied Successfully"
                                                )
                                              }
                                              className="simple-link purple"
                                            >
                                              <img src={iconcopy} alt="Copy" />{" "}
                                              Copy Address
                                            </Link>
                                          )}
                                        </div>
                                      </Col>
                                      <Col xl="12" lg="12" md="12" sm="12">
                                        <ul className="my-token-list w-100">
                                          <li>
                                            <p className="m-0">
                                              Tokens Ordered:
                                            </p>
                                            <p className="m-0">
                                              {parseFloat(tokens)
                                                .toFixed(2)
                                                ?.toString()
                                                .replace(CommasRegex, ",") ??
                                                0}{" "}
                                              <span className="lightgrey-color font-weight-normal">
                                                $NDFT
                                              </span>
                                            </p>
                                          </li>
                                          <li>
                                            <p className="m-0">
                                              Purchase Bonus:
                                            </p>
                                            <p className="m-0">
                                              {parseFloat(tokens * bonus)
                                                .toFixed(2)
                                                ?.toString()
                                                .replace(CommasRegex, ",") ??
                                                0}{" "}
                                              <span className="lightgrey-color font-weight-normal">
                                                $NDFT
                                              </span>
                                            </p>
                                          </li>

                                          {ExtraBonus && (
                                            <li>
                                              <p className="m-0">
                                                Referral Bonus:
                                              </p>
                                              <p className="m-0">
                                                {ExtraBonus ? tokens * 0.1 : 0}
                                                <span className="lightgrey-color font-weight-normal">
                                                  $NDFT
                                                </span>
                                              </p>
                                            </li>
                                          )}
                                          {promotionBonus && PromoCodetext && (
                                            <li>
                                              <p className="m-0">
                                                Promo Bonus:
                                              </p>
                                              <p className="m-0">
                                                {tokens *
                                                  (promotionBonus / 100)}
                                                <span className="lightgrey-color font-weight-normal">
                                                  $NDFT
                                                </span>
                                              </p>
                                            </li>
                                          )}
                                        </ul>
                                      </Col>
                                      <div className="spacer-20" />
                                      <Col
                                        xl="12"
                                        lg="12"
                                        md="12"
                                        className="text-center"
                                      >
                                        <h4 className="notranslate chakra">
                                          <b>
                                            <img
                                              src={arrowleft}
                                              alt="Arrow Left"
                                            />{" "}
                                            You Get:{" "}
                                            <img
                                              src={arrowright}
                                              alt="Arrow Right"
                                            />
                                          </b>
                                        </h4>
                                        <h3 className="token-text-cntnr notranslate chakra">
                                          <img
                                            src={iconlogo}
                                            alt="ALGo Token"
                                            className="mx-2"
                                          />
                                          <span>
                                            {parseFloat(tokens)
                                              .toFixed(2)
                                              ?.toString()
                                              .replace(CommasRegex, ",") ?? 0}
                                            <span className="font-weight-normal ml-2 f-18 lightgrey-color Titillium">
                                              $NDFT
                                            </span>
                                          </span>
                                          <span className="mx-3 f-18">≈</span>
                                          <span>
                                            <img
                                              className="mr-2"
                                              style={{ width: "30px" }}
                                              src={
                                                selectLogo?.logo_url
                                                  ? `${CurrencyImageUrl}${selectLogo?.logo_url}`
                                                  : iconusdc
                                              }
                                              alt="USDC Token"
                                            />
                                            {AmountToSend}
                                            <span className="font-weight-normal ml-2 f-18 lightgrey-color Titillium">
                                              {selectedCurrency.value?.toUpperCase()}{" "}
                                            </span>
                                          </span>
                                        </h3>
                                        <div className="spacer-20" />
                                        <p className="f-20 text-left lightgrey-color m-0">
                                          Connected Wallet{" "}
                                          <b className="lighter-yellow">
                                            {address?.slice(0, 5) +
                                              "..." +
                                              address?.slice(-5)}
                                          </b>{" "}
                                          <a
                                            className="darkbluecolor text-decor"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              navigate(
                                                "/walletTransactions/transaction"
                                              );
                                            }}
                                            to={void 0}
                                          >
                                            Transactions history
                                          </a>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default BuyToken;
