import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import { Row, Container, Col, Table } from "react-bootstrap";

function TransactionHistory() {

  return (
    <>
      {/* Transaction History Starts Here */}
      <section className="inner-page container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h2>
                  <img src={arrowleft} alt="Arrow Left"/> {' '}Transaction <span>History</span>{' '}<img src={arrowright} alt="Arrow Right"/>
                </h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                <div className="spacer-50"/>
                <div className="table-container">
                  <div className="table-container-inner">
                    <Table striped bordered className="text-center">
                      <thead>
                        <tr>
                          <th>Transaction ID</th>
                          <th>Transaction Date</th>
                          <th>Tokens</th>
                          <th>Amount in Cryptocurrency</th>
                          <th>Amount in US Dollars</th>
                          <th>To Wallet Address</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>MAY 12, 2024</td>
                          <td>USDT</td>
                          <td>10,000.08</td>
                          <td>10,000.08</td>
                          <td className="lighter-yellow">0X232E...01C2</td>
                          <td className="greencolor">Success</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>DEC 12, 2024</td>
                          <td>BTC</td>
                          <td>0,5123</td>
                          <td>21,531.19</td>
                          <td className="lighter-yellow">0xcf9f23aff3b85g59d3f4j8s9dh12854e825a2809</td>
                          <td className="redcolor">Failed</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>MAY 12, 2024</td>
                          <td>USDT</td>
                          <td>10,000.08</td>
                          <td>10,000.08</td>
                          <td className="lighter-yellow">0xcf9f23aff3b85g59d3f4j8s9dh12854e825a2809</td>
                          <td className="greencolor">Success</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>MAY 12, 2024</td>
                          <td>USDT</td>
                          <td>10,000.08</td>
                          <td>10,000.08</td>
                          <td className="lighter-yellow">0xcf9f23aff3b85g59d3f4j8s9dh12854e825a2809</td>
                          <td className="redcolor">Rejected</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="spacer-50"/>
              </Col>
              <Col xl="12" lg="12" md="12" className="text-center">
                <Link className="reg-btn br-radius trans" to="/">
                  Back To Homepage
                </Link>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Transaction History Ends Here */}
    </>
  );
}
export default TransactionHistory;
