import React from "react";
import { Button } from "react-bootstrap";
import iconchat from "../Assets/img/admin-icons/icon-chat.png";
function ChatButton() {
  return (
    <>
      <Button className="chat-span" style={{display:'none'}}>
        <img src={iconchat} alt="Chat"/>
      </Button>
    </>
  );
}
export default ChatButton;