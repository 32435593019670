import React, { useState, useEffect } from 'react';

const CountdownTimer = ({Valid_Date}) => {
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const expiryDate = new Date(Valid_Date); // Replace with your expiration date
      const now = new Date();
      // console.log(now)
      // console.log(expiryDate)
      const timeDifference = expiryDate.getTime() - now.getTime();

      if (timeDifference > 0) {
        const totalSeconds = Math.floor(timeDifference / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeRemaining('Expired');
      }
    };

    // Calculate initially and start interval to update every second
    calculateTimeRemaining();
    const interval = setInterval(calculateTimeRemaining, 1000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [Valid_Date]);

  return (
    <>
                            <p className="f-18 m-0">Expiring in</p>
                            <h5 className="m-0  font-weight-bolder whitecolor">
                            {timeRemaining}
                            </h5>
                          </>
  );
};

export default CountdownTimer;