// import { ethereumAddressRegex } ;

import { Notifications } from "../Components/Toast";

// countdownUtils.js

import moment from 'moment';

export const calculateDuration = (eventTime) =>
  moment.duration(Math.max(eventTime - Math.floor(Date.now() / 1000), 0), 'seconds');


export const CopyToClipboardButton = async (textToCopy ,message) => {

      try {
        await navigator.clipboard.writeText(textToCopy);
        Notifications('success',message)
        // alert('Copied to clipboard!');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
}

export const validateEthereumAddress = (address) => {

    return /^0x[0-9a-fA-F]{40}$/.test(address);
  };

