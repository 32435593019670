import React from "react";
import { Row, Container, Col, Button } from "react-bootstrap";
import Gleam from "../Assets/img/Giveaway/gleam.png";
import image from "../Assets/img/Giveaway/img.png";
import Lock from "../Assets/img/Giveaway/lock.png";
import wallet from "../Assets/img/Icons/icon-wallet.png";
import twitter from "../Assets/img/Icons/icon-twitter.png";
import Discord from "../Assets/img/Icons/discord.png";
import telegram from "../Assets/img/Icons/icon-telegram.png";
import { PresaleNowButton } from "./Buttons";
import { Link } from "react-router-dom";
import { DiscordIcon, GiveawayIcon, teleGramUrl, twitterIcon } from "../Constants";
function EntiresPanel({setSignupShow,setloginShow}) {
  return (
    <>
      <div className="entries-pnl">
        <div className="entries-head">
          {/* <ul>
            <li>
              <h4>0</h4>
              <p>Your Entries</p>
            </li>
            <li>
              <h4>325,538</h4>
              <p>Total Entries</p>
            </li>
            <li>
              <h4>25</h4>
              <p>Days Left</p>
            </li>
          </ul> */}
        </div>
        <div className="entries-body">
        
          <a 
          target="_blank"
          class="e-widget no-button" href={GiveawayIcon} rel="nofollow"> WIN $250k With Algotech   </a>


          <div className="spacer-40"/>
          <p>🎁🎁🎁 Don't miss out on the $250K NDFT Giveaway! 🎁🎁🎁</p>
          <p>
            Grab your chance to be one of the 10 fortunate winners to snag
            $25,000 worth of NDFT each! 🌟
          </p>
          <p>💰💰 Share & Join to Boost Your Winning Odds! 💰💰</p>
          <p>
            Act now! Participate and conquer all tasks to unlock BONUS Entries
            for an even greater shot at winning! 🏆🎉
          </p>
          </div>
          {/* <div className="spacer-10"/>
          <Link to={void(0)} className="reg-btn small blue" onClick={()=>{
            setSignupShow(true)
        
          }} >Join Presale</Link>
          <div className="spacer-30"/>
          <Link className="purple-link">
            <span>i</span> How to Join?
          </Link>
          <div className="spacer-20"/>
          <p>
            <span>✔</span> <b>Step 1:</b> Join Presale.
          </p>
          <p>
            {" "}
            <span>✔</span> <b>Step 2:</b> Complete All Quests <br></br> Maximize
            your entries by following all tasks diligently!
          </p>
          <p>
            {" "}
            <span>✔</span> <b>Step 3:</b> Refer and Earn More Entries <br></br>
            Share the joy! Get +15 entries for every valid referral.
          </p>
          <p>
            <b>Don't miss out-claim your $25,000 worth of NDFT!</b> 🌟
          </p>
          <div className="spacer-20"/>
          <ul className="follow-entry-list">
            <li>
              <p className="center-txt">5 Ways to Enter</p>
            </li>
            <li>
              <Link >
                <p  onClick={()=>{
                      setSignupShow(true)
                }}>
                  <img src={wallet} alt="wallet" /> Join Presale{" "}
                </p>{" "}
                <span>+1</span>
              </Link>
            </li>
            <li>
              <Link target="_blank" to={twitterIcon }>
                {" "}
                <p >
                  <img src={twitter} alt="twitter" /> Follow @algotech on
                  Twitter{" "}
                </p>{" "}
                <span>+1</span>
              </Link>
            </li>
            <li>
              <Link target="_blank" to={DiscordIcon }>
                {" "}
                <p>
                  <img src={Discord} alt="Discord" /> Follow @algotech on
                  Discord{" "}
                </p>{" "}
                <span>+1</span>
              </Link>
            </li>
            <li>
              <Link target="_blank" to={teleGramUrl }>
                {" "}
                <p>
                  <img src={telegram} alt="telegram" /> Subscribe @algotech on
                  Telegram{" "}
                </p>{" "}
                <span>+1</span>
              </Link>
            </li>
          </ul>
          <h4>
            {" "}
            <img src={Lock} alt="Lock" /> <b>Unlock 100 More Entries</b>
          </h4>
          <p>Complete the above entries to unlock the rest!</p>
        </div>*/}
        <div className="entries-foot">
          <ul>
            <li>
              <Link href="/TermsConditions">Terms & Conditions</Link>
            </li>
            <li>
              <img src={Gleam} alt="Gleam" /> Powered by Gleam
            </li>
          </ul>
        </div> 
      </div>
    </>
  );
}
export default EntiresPanel;
